import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { reducer as userReducer, NAME as user } from './user';
import {
  reducer as campaignsReducer,
  REDUCER_KEY as campaigns,
} from './campaigns';
import {
  reducer as advertisersReducer,
  REDUCER_KEY as advertisers,
} from './clients';
import { reducer as creativesReducer, NAME as creatives } from './creatives';
import { reducer as lineitemsReducer, NAME as lineitems } from './lineitems';
import { reducer as statisticsReducer, NAME as statistics } from './statistics';
import { reducer as settingsReducer, NAME as settings } from './settings';
import { reducer as constsReducer, NAME as consts } from './consts';
import { reducer as chatsReducer, REDUCER_KEY as chats } from './chats';
import {
  reducer as proposalsReducer,
  REDUCER_KEY as proposals,
} from './proposals';
import {
  reducer as productsReducer,
  REDUCER_KEY as products,
} from './products';
import { reducer as usersReducer, REDUCER_KEY as users } from './users';
import { reducer as adSlotReducer, REDUCER_KEY as adSlot } from './adslots';
import {
  reducer as adFormatReducer,
  REDUCER_KEY as adFormat,
} from './adformats';
import {
  reducer as notificationReducer,
  REDUCER_KEY as notifications,
} from './notifications';
import { reducer as basketsReducer, REDUCER_KEY as baskets } from './baskets';

const rootReducer = combineReducers({
  [consts]: constsReducer,
  [user]: persistReducer({ key: [user], storage }, userReducer),
  [users]: usersReducer,
  [advertisers]: advertisersReducer,
  [campaigns]: campaignsReducer,
  [creatives]: creativesReducer,
  [lineitems]: lineitemsReducer,
  [statistics]: statisticsReducer,
  [settings]: settingsReducer,
  [proposals]: proposalsReducer,
  [products]: productsReducer,
  [adSlot]: adSlotReducer,
  [adFormat]: adFormatReducer,
  [notifications]: notificationReducer,
  [chats]: chatsReducer,
  [baskets]: basketsReducer,
});
export default rootReducer;
